import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import '../components/NavBar.css';
import logo from '../assets/img/EnoLogo.svg';

function NavBar() {
    const navRef = useRef();

    const showNavbar = () =>{
        navRef.current.classList.toggle("responsive_nav");
    }
    

       // Función para ocultar el menú
       const hideNavbar = () => {
        if (navRef.current.classList.contains("responsive_nav")) {
            showNavbar(); // Oculta el menú
        }
    };

    return (
        <header>
            <h2 className="logo__header"
            ><img src={logo}/>
            </h2>
            <nav ref={navRef}>
                <a href="#trade" onClick={hideNavbar}>Trade</a>
                <a href="#features" onClick={hideNavbar}>Utility</a>
                <a href="#whitepaper" onClick={hideNavbar}>Whitepaper</a>
                <a href="#partners" onClick={hideNavbar}>Partners</a>
                <a href="#media" onClick={hideNavbar}>Media</a>
                <a href="#core" onClick={hideNavbar}>Core Team</a>
                <button className="nav-btn nav-close-btn" onTouchStart={showNavbar}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </nav>
            <button className="nav-btn" onTouchStart={showNavbar}>
                <FontAwesomeIcon icon={faBars} />
            </button>
        </header>
    );
}

export default NavBar;
