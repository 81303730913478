import React from 'react';
import './App.css';
import ContentSystem from './ContentSystem';
import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';


function App() {
  return (
    <div className="App">
      <ContentSystem />
    </div>
  );
}

export default App;

