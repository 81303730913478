import React from "react";

//Estilos
import "./ContentSystem.css";
// Imágenes
import enologo from "./assets/img/EnoLogo.png";
import city from "./assets/img/City.png";
import mapa from "./assets/img/Mapa.png";
//Eno trade
import uniswap from "./assets/img/Uniswap.png";
import bitmart from "./assets/img/Bitmart.png";
import latoken from "./assets/img/latoken.png";
import coingeckro from "./assets/img/Coingeckologo.png";
import coinmarket from "./assets/img/Coinmarketcap.png";
//Media
import benzinga from "./assets/img/Media/Benzinga.png";
import forbes from "./assets/img/Media/Forbes.png";
import investing from "./assets/img/Media/Investing.png";
import marketwatch from "./assets/img/Media/Marketwatch.png";
import yahoo from "./assets/img/Media/Yahoo.png";
import staking from "./assets/img/Stacking.png";
import discount from "./assets/img/Discount.png";
import buyback from "./assets/img/Buyback3.png";

//Componentes
import Navbar from "./components/NavBar";
import Carousel from "./components/Carousel";
// Videos
import vortex from "./assets/img/Vortex.mp4";
import discountvideo from "./assets/img/Discount.webm";
import buybackvideo from "./assets/img/Buyback.webm";
import stakingvideo from "./assets/img/Staking.webm";
import castle from "./assets/img/Castle.webm";
//Core Team
import albertoFernandez from "./assets/img/Core team/Alberto.png";
import joseVilata from "./assets/img/Core team/José Vilalta.png";
import davidMarquez from "./assets/img/Core team/David.png";
import antonioOrnelas from "./assets/img/Core team/Antuan.png";
import paolaGarrido from "./assets/img/Core team/Paola.png";
import karimeSanchez from "./assets/img/Core team/Karime.png";
import braulioStefanoo from "./assets/img/Core team/Braulio.png";
import angelArzate from "./assets/img/Core team/Antonio.png";
import JuanDeLaRosa from "./assets/img/Core team/Juan José.png";
import horacioQuintana from "./assets/img/Core team/Horacio.png";
import oliverCamacho from "./assets/img/Core team/Oliver.png";
import lauraFaride from "./assets/img/Core team/Faride.png";
import armandoRosales from "./assets/img/Core team/Armando.png";
import joshwaCamacho from "./assets/img/Core team/Josh.png";



// Otros recursos
import tokenomics from "./assets/img/Tokenomics.png";

/* ============ FONT A W E S O M E ============ */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faLinkedin,
  faXTwitter,
  faInstagram,
  faDiscord,
  faTelegram,
  faRedditAlien,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const App = () => {
  return (
    <div className="app__container">
      {/* ======= Navbar ======= */}
      <Navbar />
      {/* ======= Hero ======= */}
      <section className="hero__container">
        <div className="hero__content">
          <div className="hero__left">
            {/* <img src={enologo} alt="Logo" className="hero__logo" /> */}

            {/* Iconos Sociales */}

            <div className="global__icons">
              <div className="social__container">
                <div className="social__icons__container">
                  <div className="social__icons icon__list">
                    <a
                      href="https://www.youtube.com/channel/UClFLsvU78zRxuI-q4_WT4-g/videos"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a
                      href="https://www.facebook.com/ENOtoken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a
                      href="https://mx.linkedin.com/company/enotoken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a
                      href="https://twitter.com/Enotoken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                    <a
                      href="https://www.instagram.com/enotoken/?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                      href="https://discord.com/invite/3eZewHzJU5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a
                      href="https://t.me/EnoToken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="hero__title">
              THE FUTURE OF DECENTRALIZED SOCIAL CLUBS
            </h2>
            <span className="span__hero">
              ENO revolutionizes social clubs with a web3 platform that enables
              the creation and growth of decentralized communities, providing
              essential tools for success, including tokens and NFT's
            </span>
            <div className="hero__btn__container">
              <button className="hero__btn color-1">
                <a href="https://docs.enotoken.io/" target="_blank">
                  Whitepaper
                </a>
              </button>
              <button className="hero__btn color-2">
                <a href="https://github.com/AuditRateTech/Smart-Contract-Audits/blob/main/EnoToken_0x1C3d163219Bb74f430411b95D66b72056f366eC1.pdf" target="_blank">
                  Audit
                </a>
              </button>
            </div>

            {/* <p className="contract__address">Contract Address</p>
            <a href="0x1c3d163219bb74f430411b95d66b72056f366ec1" className="contract__link">0x1c3d163219bb74f430411b95d66b72056f366ec1</a> */}
          </div>
          <div className="hero__right">
            {/* <video src={castle} autoPlay loop muted /> */}
            <img src={city} alt="Logo" className="city__image" />
          </div>
        </div>
      </section>
      {/* ======= Trade Eno Token at ======= */}
      <section className="trade__container" id="trade">
        <h2 className="section__title">Trade Eno Token at</h2>
        <div className="trade__content">
          {/* Left */}
          <div className="trade__left">
            <div className="trade__box1">
              <a href="https://app.uniswap.org/swap?inputCurrency=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9&outputCurrency=0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F&chain=arbitrum" target="_blank">
                <button className="trade__label">
                  <img
                    src={uniswap}
                    style={{ width: "70%" }}
                    alt="Logo"
                    className="latoken"
                  />
                </button>
              </a>
              <p className="trade__btn">UNISWAP</p>
            </div>

            <div className="trade__box2">
              <a href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=ENO_USDT" target="_blank">
                <button className="trade__label">
                  <img
                    src={bitmart}
                    style={{ width: "50%" }}
                    alt="Logo"
                    className="latoken"
                  />
                </button>
              </a>
              <p className="trade__btn">BITMART</p>
            </div>
          </div>

          <div className="trade__box3">
            <a href="https://latoken.com/exchange/ENO_USDT" target="_blank">
              <button className="trade__label">
                <img
                  src={latoken}
                  style={{ width: "70%" }}
                  alt="Logo"
                  className="latoken"
                />
              </button>
            </a>
            <p className="trade__btn">LATOKEN</p>
          </div>
        </div>
      </section>
      {/* ======= Eno Token featured at ======= */}
      <section className="featured__container" id="features">
        <h2 className="section__title">Eno Token featured at</h2>
        <div className="trade__content">
          {/* Left */}
          <div className="trade__right">
            <div className="trade__box1">
              <a href="https://coinmarketcap.com/es/currencies/eno/" target="_blank">
                <button className="trade__label">
                  <img
                    src={coinmarket}
                    style={{ width: "70%" }}
                    alt="Logo"
                    className="latoken"
                  />
                </button>
              </a>
              <p className="trade__btn">COINMARKETCAP</p>
            </div>

            <div className="trade__box1">
              <a href="https://www.coingecko.com/es/monedas/eno" target="_blank">
                <button className="trade__label">
                  <img
                    src={coingeckro}
                    style={{ width: "70%" }}
                    alt="Logo"
                    className="latoken"
                  />
                </button>
              </a>
              <p className="trade__btn">COINGECKO</p>
            </div>


          </div>
        </div>
      </section>
      {/* ======= Tokenomics ======= */}
      <section className="tokenomics__container">
        <h2 className="section__title">Tokenomics</h2>
        {/* <span className="span__section">25 million ENO Token Fixed Supply</span> */}
        <a href="https://docs.enotoken.io/usdeno-token/usdeno-tokenomics" target="_blank" rel="noopener noreferrer" >
          <img
            src={tokenomics}
            style={{
              width: "100%",
              maxWidth: "800px",
              height: "auto",
              marginBottom: "70px",
            }}
            alt="tokenomics"
          />
        </a>
      </section>
      {/*======= Token contract ======= */}
      <section className="tokencontract__container" id="whitepaper">
        <h2 className="section__title">Token contract</h2>
        <p className="section__subtitle__box">
          Use the contract information below to add the ENO token to your wallet
        </p>
        <div className="tokencontract__boxes">
          <div className="tokencontract__box1">
            <div className="token__box">
              <p className="contract__title">Address</p>
              <span className="contract__subtitle__contract">
                <a
                  href="https://etherscan.io/token/0x1C3d163219Bb74f430411b95D66b72056f366eC1"
                  target="_blank"
                  rel="noreferrer"
                >
                  0x1C3d163219Bb74f430411b95D66b72056f366eC1
                </a>
              </span>
            </div>

            <div className="token__box">
              <p className="contract__title">Decimal</p>
              <span className="contract__subtitle">18</span>
            </div>

            <div className="token__box">
              <p className="contract__title">Network</p>
              <span className="contract__subtitle">Ethereum</span>
            </div>

            <div className="token__box">
              <p className="contract__title">Token Symbol</p>
              <span className="contract__subtitle">ENO</span>
            </div>
          </div>
          {/* token box 2 */}
          <div className="tokencontract__box2">
            <div className="token__box">
              <p className="contract__title">Address</p>
              <span className="contract__subtitle__contract">
                <a
                  href="https://arbiscan.io/address/0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F"
                  target="_blank"
                  rel="noreferrer"
                >
                  0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F
                </a>
              </span>
            </div>

            <div className="token__box">
              <p className="contract__title">Decimal</p>
              <span className="contract__subtitle">18</span>
            </div>

            <div className="token__box">
              <p className="contract__title">Network</p>
              <span className="contract__subtitle">Arbitrum</span>
            </div>

            <div className="token__box">
              <p className="contract__title">Token Symbol</p>
              <span className="contract__subtitle">ENO</span>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Token Utility ======= */}
      <section className="token__utility__container" id="utility">
        <h2 className="section__title">ENO Token's Utility</h2>
        <div className="token__utility__content">
          <div className="token__utility__box">
            <div className="token__boxes">
              <video src={stakingvideo} autoPlay loop muted />
              <img src={staking} alt="stacking" />
              <p className="token__label">Stacking</p>
              <button className="hero__btn color-1">
                <a href="https://docs.enotoken.io/usdeno-token/usdeno-utility" target="_blank" >
                  Learn More
                </a>
              </button>
            </div>

            <div className="token__boxes">
              <video src={discountvideo} autoPlay loop muted />
              <img src={discount} alt="discount" />
              <p className="token__label">Incentives and Rewards</p>
              <button className="hero__btn color-1">
                <a href="https://docs.enotoken.io/usdeno-token/usdeno-utility" target="_blank" >
                  Learn More
                </a>
              </button>
            </div>

            <div className="token__boxes">
              <video src={buybackvideo} autoPlay loop muted />
              <img src={buyback} alt="buyback" />
              <p className="token__label">Payment for Services</p>
              <button className="hero__btn color-1">
                <a href="https://docs.enotoken.io/usdeno-token/usdeno-utility" target="_blank" >
                  Learn More
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Join Eno - VIDEO ======= */}
      <section className="join__container">
        <div className="blurry__sphere"></div>
        <div className="join__content">
          <div className="join__left">
            <span>JOIN TO THE</span>
            <h2 className="join__title">ENO COMMUNITY</h2>
            <p className="join__subtitle">
              The intersection of our passions and Web3 technology enriches
              every aspect of our lives, making ENO's social media into centers
              of engaging dialogues. Dive into this extraordinary journey and
              become part of our community!
            </p>
            <div className="global__icons">
              <div className="social__container">
                <div className="social__icons__container">
                  <div className="social__icons icon__list">
                    <a
                      href="https://t.me/EnoToken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a
                      href="https://discord.com/invite/3eZewHzJU5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a
                      href="https://twitter.com/Enotoken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                    <a
                      href="https://mx.linkedin.com/company/enotoken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="join__right">
            <video src={vortex} autoPlay loop muted />
          </div>
        </div>
      </section>
      {/* ======= Our Partners - Carrousel ======= */}
      <section className="our__partners__container" id="partners">
        <h2 className="section__title">Our Partners</h2>
        <Carousel />
        <div className="our__partners__content">
          <img
            src={mapa}
            style={{ width: "100%", maxWidth: "800px", height: "auto" }}
            alt="mapa-eno"
          />
        </div>
      </section>
      {/* ======= Media ======= */}
      <section className="media__container" id="media">
        <h2 className="section__title">Media</h2>
        <div className="media__content">

          <div className="benzinga">
            <a href="https://www.benzinga.com/" target="_blank" rel="noopener noreferrer">
              <img src={benzinga} alt="benzinga" />
            </a>
          </div>

          <div className="forbes">
            <a href="https://www.forbes.com/" target="_blank" rel="noopener noreferrer">
              <img src={forbes} alt="forbes" />
            </a>
          </div>

          <div className="investing">
            <a href="https://www.investing.com/" target="_blank" rel="noopener noreferrer">
              <img src={investing} alt="investing" />
            </a>
          </div>

          <div className="marketwatch">
            <a href="https://www.marketwatch.com/" target="_blank" rel="noopener noreferrer">
              <img src={marketwatch} alt="marketwatch" />
            </a>
          </div>

          <div className="yahoo">
            <a href="https://finance.yahoo.com" target="_blank" rel="noopener noreferrer">
              <img src={yahoo} alt="yahoo" />
            </a>
          </div>
        </div>
      </section>
      {/* ======= Core Team - Grid Carrousel 5x4 ======= */}
      <section className="core__team__container" id="core">
        <h2 className="section__title">Core Team</h2>
        <div className="core__team__content">
          <div className="core__boxes">

            {/* Alberto Fernández */}
            <div className="core__main">
              <div className="core__box">
                <img src={albertoFernandez} alt="albertoFernandez" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Alberto<br />Fernández</h2>
                <span className="span__core">CEO & Co-founder</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/alberto-fernandez-peregrina/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* José Vilata */}
            <div className="core__main">
              <div className="core__box">
                <img src={joseVilata} alt="joseVilata" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">José<br />Vilata</h2>
                <span className="span__core">Treasury & Co-founder</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/jos%C3%A9-manuel-vilalta-nava-6063a6166/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* David Márquez */}
            <div className="core__main">
              <div className="core__box">
                <img src={davidMarquez} alt="davidMarquez" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">David<br />Márquez</h2>
                <span className="span__core">Chief Operations Officer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/david-m%C3%A1rquez-798698191/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Antonio Ornelas */}
            <div className="core__main">
              <div className="core__box">
                <img src={antonioOrnelas} alt="antonioOrnelas" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Antonio<br />Ornelas</h2>
                <span className="span__core">Chief Creative Officer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/tuna-garc%C3%ADa-1b7504232/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Ángel Arzate */}
            <div className="core__main">
              <div className="core__box">
                <img src={angelArzate} alt="angelArzate" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Ángel<br />Arzate</h2>
                <span className="span__core">Chief Marketing Officer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/angel-arzate-moreno-4349a1ab/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Juan Jose de la Rosa */}
            <div className="core__main">
              <div className="core__box">
                <img src={JuanDeLaRosa} alt="juanJose" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Juan José<br />de la Rosa</h2>
                <span className="span__core">Chief TechnologyOfficer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/juan-jose-de-la-rosa/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Horacio Quintana */}
            <div className="core__main">
              <div className="core__box">
                <img src={horacioQuintana} alt="horacioQuintana" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Horacio<br />Quintana</h2>
                <span className="span__core">Chief Business Developer Officer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/horacio-quintana-03319a9/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Oliver Camacho */}
            <div className="core__main">
              <div className="core__box">
                <img src={oliverCamacho} alt="oliverCamacho" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Oliver<br />Camacho</h2>
                <span className="span__core">Graphic Designer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/olivercamachob/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Paola Garrido */}
            <div className="core__main">
              <div className="core__box">
                <img src={paolaGarrido} alt="paolaGarrido" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Paola<br />Garrido</h2>
                <span className="span__core">Communication Analyst</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/paolagarridobarrera/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Karime Sánchez */}
            <div className="core__main">
              <div className="core__box">
                <img src={karimeSanchez} alt="karimeSanchez" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Karime<br />Sánchez</h2>
                <span className="span__core">Project Manager</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/karime-snr" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Braulio Stefano */}
            <div className="core__main">
              <div className="core__box">
                <img src={braulioStefanoo} alt="braulioStefano" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Braulio<br />Stefano</h2>
                <span className="span__core">Web Developer</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/brauliostefano" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Armando Rosales */}
            <div className="core__main">
              <div className="core__box">
                <img src={armandoRosales} alt="armandoRosales" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Armando<br />Rosales</h2>
                <span className="span__core">Web3 Content</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/armando-rosales-739b8061" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Laura Faride */}
            <div className="core__main">
              <div className="core__box">
                <img src={lauraFaride} alt="lauraFaride" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Laura<br />Faride</h2>
                <span className="span__core">Wine Content</span>
                <p className="core__links">
                  <a href="https://www.linkedin.com/in/faride-vargas-09b56748?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" >
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

            {/* Joshwa Camacho */}
            <div className="core__main">
              <div className="core__box">
                <img src={joshwaCamacho} alt="joshwaCamacho" className="core__image" />
              </div>
              <div className="core__details">
                <h2 className="title__core">Joshwa<br />Camacho</h2>
                <span className="span__core">UX/UI Designer</span>
                <p className="core__links">
                  <a href="https://mx.linkedin.com/company/enotoken" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} className="gridIcon" />
                  </a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* ===================== Footer ===================== */}
      <div className="footer__content">
        <div className="footer__left">
          <div>
            <img src={enologo} alt="" className="footer__logo" />
            <p className="span__section" id="span__footer">
              Where friends, families and dreams unite
            </p>
            {/* Iconos Sociales */}
            <div className="global__icons">
              <div className="social__container">
                <div className="social__icons__container">
                  <div className="social__icons icon__list">
                    <a href="https://www.youtube.com/channel/UClFLsvU78zRxuI-q4_WT4-g/videos" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>

                    <a href="https://www.facebook.com/ENOtoken" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>

                    <a href="https://mx.linkedin.com/company/enotoken" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>

                    <a href="https://twitter.com/Enotoken" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>

                    <a href="https://www.instagram.com/enotoken/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>

                    <a href="https://discord.com/invite/3eZewHzJU5" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>

                    <a href="https://t.me/EnoToken" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>

                    {/* <a
                      href="https://t.me/EnoToken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>

                    <a
                      href="https://t.me/EnoToken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__right">
          {/* <h2 className="section__subtitle__footer">Join Our Newsletter</h2>
          Newsletter
          <div class="newsletter__badge">
            <div class="input-group">
              <input
                type="text"
                class="form__footer form-control"
                placeholder="eno@example.com"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
              >
                Get a badge
              </button>
            </div>
          </div> */}

          <div className="social__links">
            <div className="social__footer">
              <div className="socials">
                <p className="footer__link">Support</p>
                <a
                  className="name__link"
                  href="https://discord.com/invite/3eZewHzJU5"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </div>

              <div className="socials">
                <p className="footer__link">Learn</p>
                <a
                  className="name__link"
                  href="https://docs.enotoken.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whitepaper
                </a>
              </div>

              <div className="socials">
                <p className="footer__link">News</p>
                <a
                  className="name__link"
                  href="https://enotoken.io/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== All rights Reserved ===================== */}
      <div className="subfooter__content">
        <div className="footer__container">
          <div className="footer__box">
            <a href="google.com"> Terms of use </a>
          </div>

          <div className="footer__box">
            <a href="google.com"> Privacy Policy </a>
          </div>

          <div className="footer__box">
            <a href="google.com"> Cookie Policy </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
