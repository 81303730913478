  import BaconBar from '../assets/img/Partners/Bacon Bar.png'
  import BaronBalche from '../assets/img/Partners/BaronBalche.png'
  import ChateauC from '../assets/img/Partners/ChateauC.png'
  import COEN from '../assets/img/Partners/ConciertoEnologico.png'
  import ElyCastro from '../assets/img/Partners/Ely Castro.png'
  import BosqueReal from '../assets/img/Partners/GBosqueReal.png'
  import Marques from '../assets/img/Partners/Marques.png'
  import MMC from '../assets//img/Partners/MMC.png'
  import prudencianas from '../assets/img/Partners/Pudencianas.png'
  import SJL from '../assets/img/Partners/SJL.png'
  import TierraAdentro from '../assets/img/Partners/Tierra-Adentro.png'

  import '../components/Carousel.css'

  // Import Swiper React components
  import { Swiper, SwiperSlide } from 'swiper/react';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';

  import { Autoplay, Pagination, Navigation } from 'swiper/modules';

  export default function App() {
    return (
      <>
        <Swiper

          autoplay={{
            delay: 1000,
            disableOnInteraction: false
          }}
          loop={true}
          slidesPerView={3}
          spaceBetween={4}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={BaconBar} alt="BaconBar" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={BaronBalche} alt="BarronBalche" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={ChateauC} alt="ChateauCamou" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={COEN} alt="Concierto Enologico" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={ElyCastro} alt="ElyCastro" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={BosqueReal} alt="Bosque Real" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={Marques} alt="MarquesGrinon" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={MMC} alt="MMC" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={prudencianas} alt="prudencianas" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={SJL} alt="SJL" />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href="https://docs.enotoken.io/partners" target="_blank" rel="noopener noreferrer">
              <img src={TierraAdentro} alt="Tierra Adentro" />
            </a>
          </SwiperSlide>

        </Swiper>
      </>
    );
  }
